import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

const body = document.querySelector("body");
const script = document.createElement("script");
script.src = "https://rehabs-in.com/sdk/portal/portal.bundle.js";
script.defer = true;

script.id = "#rehabs-portal-script";
script.setAttribute("partner-key", "0b19769288e745768d25cd0283cc47a4");
script.setAttribute("default-open", "false");

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

body.appendChild(script);

root.render(<App />);
